'use client';

import Title from 'antd/es/typography/Title';
import React from 'react';
import { useIntl } from 'react-intl';

const Page: React.FC = () => {
  const intl = useIntl();

  return (
        <Title level={4}>{intl.formatMessage({ id: 'welcome' })}</Title>
  );
};

export default Page;
